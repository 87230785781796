import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import { Form } from "ant-design-vue";
// import { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@fortawesome/fontawesome-free/js/all.min.js";
// import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const remote = require("loglevel-plugin-remote");
const log = require("loglevel");
Vue.prototype.$log = log;
// Vue.use(Form);
// Vue.prototype.$message = message;
log.enableAll();

remote.apply(log, {
  url: "/api/logger",
  stacktrace: {
    depth: 5,
  },
  format(item: any) {
    item.level = item.level.label;
    return item;
  },
});

window.onerror = function(msg, url, line, col, error) {
  let extra = !col ? "" : "\ncolumn: " + col;
  extra += !error ? "" : "\nerror: " + error;
  log.error(extra);
};

async function setup() {
  // put  here as method to allow for async setup
  // issue is we need to validate the user using the saved token, and if the user is not right, we cannot even proceed. The vue router requires checking the store each time
  const auth = localStorage.getItem("auth");
  if (auth) {
    try {
      const data = await store.dispatch("Auth/UserStats");
      if (data.email) {
        store.commit("Auth/SET_LOGIN_DATA", { token: auth });
      }
    } catch (e) {
      localStorage.removeItem("auth");
      if (e.response.status == 401) {
        console.log("Invalid auth token found. Login shall be needed.");
      } else {
        console.error(e.response);
      }
    }
  }

  router.beforeEach((to, from, next) => {
    log.debug(`Going to '${to.name}' Path:'${to.fullPath}'`);

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters["Auth/isLoggedIn"]) {
        localStorage.setItem("next", to.path);
        next({
          // Redirect to original path if specified
          name: "Login",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

setup();
