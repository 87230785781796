import BaseAxios from "axios";

const axios = BaseAxios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json"
  }
});

axios.interceptors.request.use(config => {
  const token = localStorage.getItem("auth");
  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default {
  axios: axios,
  login(params: any) {
    return axios.post("/login", {
      params: params
    });
  },
  lockUserForDuration(email: string, step: number) {
    return axios.post("/lockUserForDuration", {
      email: email,
      step: step
    });
  },
  getProducts(includeInactive: any, page: number, pageSize: number) {
    let url = "/products";
    if (includeInactive) {
      url = `${url}?include_inactive=1`;
    }
    if (page && pageSize) {
      url = `${url}?page=${page}&page_size=${pageSize}`;
    }
    return axios.get(url);
  },
  singleProduct(id: number) {
    return axios.get(`/products/${id}`);
  },
  refreshToken() {
    return axios.get("/refreshToken").then(response => {
      const token = response.data.token;
      if (token) {
        localStorage.setItem("auth", token);
      }
    });
  },
  getFeaturedProducts(productId: any = null) {
    let url = "/featured-products";

    if (productId) {
      url = `${url}/${productId}`;
    }

    return axios.get(url);
  },
  confirmAuthCreds(step: string, data: any) {
    const url = `/confirm_auth_creds/${step}`;
    return axios.post(url, data);
  },
  saveReturnDetails(data: any) {
    const url = `/create_return_user`;
    return axios.post(url, data);
  },
  checkPassword(data: any) {
    const url = `/login_return_user`;
    return axios.post(url, data);
  },
  history() {
    return axios.get("/history");
  },
  redeems() {
    return axios.get("/redeems");
  },
  orders() {
    return axios.get("/orders");
  },
  singleRedeem(id: number) {
    return axios.get(`/redeems/${id}`);
  },
  payment(data: any) {
    return axios.post("/payment/", data);
  },
  checkout(data: any) {
    return axios.post("/checkout", data);
  },
  purchasePoints(data: any) {
    return axios.post("/purchasePoints", data);
  },
  user() {
    return axios.get("/user");
  },
  saveCart(data: any) {
    console.debug(data);
    return axios.post("/cart", data);
  },
  getCart() {
    return axios.get("/cart");
  },
  deleteItemCart(productId: string) {
    return axios.delete(`/cart/${productId}`);
  },
  deleteCart() {
    return axios.delete("/cart");
  }
};
