<script>
import logoImg from "../assets/new_logo.png";
import ABadge from "ant-design-vue/lib/badge";
export default {
  props: ["home"],
  components: {
    ABadge,
  },
  data() {
    return {
      logo: logoImg,
      clicked: false,
      isActive: false,
      limitPosition: 400,
      scrollState: false,
      other: false,
    };
  },
  methods: {
    updateScroll() {
      if (this.limitPosition < window.scrollY) {
        this.scrollState = true;
      }
      if (window.scrollY < this.limitPosition) {
        this.scrollState = false;
      }
    },
    toggleNav() {
      this.isActive = !this.isActive;
      this.clicked = !this.clicked;
    },
    logout() {
      this.$store.commit("Nav/SET_LOGIN_DATA", null);
      this.$store.commit("Nav/SET_USER_DATA", {});
      localStorage.removeItem("auth");
      window.location.reload();
    },
    currentPage() {
      if (this.$store.state.Nav.page) {
        this.other = true;
      } else {
        this.other = false;
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.updateScroll);
    // this.currentPage();
  },
  computed: {
    thisPage() {
      return this.$store.state.Nav.page;
    },
    loggedIn() {
      return this.$store.state.Auth.token;
    },
    points() {
      return this.$store.state.Auth.points;
    },
    cartItems() {
      return this.$store.state.Cart.cart.length;
    },
    page() {
      const page = this.$store.state.Nav.page;
      if (page) {
        return true;
      } else {
        return false;
      }
    },
    activeLink() {
      const link = this.$store.state.Nav.activeLink;
      return link;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>
<template>
  <!-- <nav class="navbar navbar-light bg-light fixed-top navbar-expand-md " id='navigation'>
    <div class="container">
      <a class="navbar-brand" href="/">Kupiku Rewards</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      </div>
    </div>
  </nav>-->
  <div id="navigation">
    <nav
      class="nav-container"
      :class="{
        change_color: scrollState,
        other_page: page,
      }"
      v-on:scroll="updateScroll"
    >
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <img :src="logo" alt class="img" />
        </router-link>
        <router-link :to="{ name: 'Home' }" class="home-link"
          ><i class="fas fa-home icon-left"></i>Home</router-link
        >
      </div>
      <template v-if="loggedIn">
        <ul class="nav-links" :class="{ active: isActive }">
          <li>
            <a
              >My Points <span class="points">{{ points }}</span></a
            >
          </li>
          <li class="cart-link">
            <span class="counter">
              <a-badge :count="cartItems" :overflow-count="9" />
            </span>
            <router-link :to="{ name: 'Cart' }"
              ><i class="fas fa-shopping-bag icon-left"></i>Cart
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'History' }">My Rewards</router-link>
          </li>
          <li><a @click.prevent="logout">Log Out</a></li>
        </ul>
        <div class="burger" :class="{ clicked: clicked }" @click="toggleNav">
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
        </div>
      </template>
      <template v-else>
        <ul class="nav-links" :class="{ active: isActive }">
          <li><router-link :to="{ name: 'Login' }">Log In</router-link></li>
          <li>
            <router-link :to="{ name: 'GettingStarted' }" class="start-btn"
              >Sign Up For Rewards!</router-link
            >
          </li>
        </ul>
        <div class="burger" :class="{ clicked: clicked }" @click="toggleNav">
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
        </div>
      </template>
    </nav>
  </div>
</template>
<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";
.home-link {
  margin-left: 4rem;
  letter-spacing: 0.18rem;
  font-size: 1.3rem;
  color: #ffffff;
  text-shadow: 0.06rem 0.07rem 0.8rem #ffffff;
  text-transform: uppercase;
  text-decoration: none;
}
.nav-container {
  padding: 0 9rem;
}
.start-btn {
  margin-left: -15rem;

  @include respond(phone) {
    // display: none;
    margin-left: 1px;
  }
}
nav {
  width: 100%;
  position: fixed;
  top: 2.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 12vh;
  font-weight: 300;
  z-index: 20;
  transition: all 0.3s ease-in;
  .logo {
    .img {
      height: 13rem;
      width: 13rem;
      transition: all 0.2s ease;
      @include respond(phone) {
        height: 9rem;
        width: 9rem;
      }
    }
  }
  .nav-links {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 45%;
    a {
      letter-spacing: 0.18rem;
      font-size: 1.3rem;
      color: #ffffff;
      text-shadow: 0.06rem 0.07rem 0.8rem #ffffff;
      text-transform: uppercase;
      text-decoration: none;
      .points {
        color: rgba(49, 233, 129, 1);
        text-shadow: 0.6px 0.7px 2px rgba(49, 233, 129, 1);
      }
      &:hover {
        text-shadow: 0.06rem 0.07rem 0.3rem #ffffff;
      }
    }
    li.cart-link {
      position: relative;
      .ant-badge {
        position: absolute;
        top: -11px;
        right: -18px;
        bottom: 0;
        min-width: 1rem;
        min-height: 1rem;
        border: none;
      }
    }

    @include respond(phone) {
      position: absolute;
      right: 0px;
      height: 92vh;
      top: 58px;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 50%;
      transform: translateX(100%);
      transition: transform 0.3s ease-in;
      z-index: 5;
      &.active {
        transform: translateX(0%);
      }
      .burger {
        display: block;
        opacity: 1;
      }
    }
  }
  .burger {
    display: none;
    // opacity: 0;
    // font-weight: 400;
    cursor: pointer;
    div {
      width: 25px;
      height: 1px;
      background-color: #ffffff;
      margin: 5px;
      transition: all 0.3s ease-in;
    }
    @include respond(phone) {
      display: block;
    }
    &.clicked {
      .line-1 {
        transform: rotate(-45deg) translate(-0.9rem, 0.3rem);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: rotate(45deg) translate(-0.8rem, -0.2rem);
      }
    }
  }
  @include respond(phone) {
    top: 0px;
    background: rgba(0, 0, 0, 0.9);
    max-height: 10vh;
    z-index: 4;
  }
}

.change_color {
  top: 0rem;
  box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.17);
  // background: rgba(0, 0, 0, 0.9);
  background-image: url("../assets/nav-bg-darker.png");
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 10vh;
  .logo {
    .img {
      height: 7rem;
      width: 7rem;
    }
  }
  @include respond(phone) {
    max-height: 10vh;
  }
}
.other_page {
  top: 0rem;
  box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.17);
  // background: rgba(0, 0, 0, 0.9);
  background-image: url("../assets/nav-bg-darker.png");
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 10vh;
  .logo {
    .img {
      height: 7rem;
      width: 7rem;
    }
  }
  @include respond(phone) {
    max-height: 10vh;
  }
}
</style>
