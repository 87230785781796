import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Mother.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
        name: "Home",
      },
      {
        path: "login",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
        name: "Login",
      },
      {
        path: "getting-started",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/GettingStarted.vue"),
        name: "GettingStarted",
      },
      {
        path: "contact-us",
        component: () =>
          import(/* webpackChunkName: "contact-us" */ "@/views/ContactUs.vue"),
        name: "ContactUs",
      },
      {
        path: "privacy-policy",
        component: () =>
          import(
            /* webpackChunkName: "contact-us" */ "@/views/PrivacyPolicy.vue"
          ),
        name: "PrivacyPolicy",
      },
      {
        path: "terms-of-use",
        component: () =>
          import(
            /* webpackChunkName: "terms-of-use" */ "@/views/TermsOfUse.vue"
          ),
        name: "TermsOfUse",
      },
      {
        path: "about-us",
        component: () =>
          import(/* webpackChunkName: "about-us" */ "@/views/AboutUs.vue"),
        name: "AboutUs",
      },
      {
        path: "cart",
        component: () =>
          import(/* webpackChunkName: "cart" */ "@/views/Cart.vue"),
        name: "Cart",
        meta: { requiresAuth: true },
      },
      {
        path: "checkout-complete",
        component: () =>
          import(/* webpackChunkName: "cart" */ "@/views/CheckoutComplete.vue"),
        name: "CheckoutComplete",
      },
      {
        path: "history",
        component: () =>
          import(
            /* webpackChunkName: "history" */ "@/views/history/History.vue"
          ),
        name: "History",
        meta: { requiresAuth: true },
      },
      {
        path: "first-time",
        component: () =>
          import(
            /* webpackChunkName: "first-time-customer" */ "@/views/FirstTimeCustomer.vue"
          ),
        name: "FirstTimeCustomer",
        meta: { requiresAuth: true },
      },
      {
        path: "setup-account",
        component: () =>
          import(
            /* webpackChunkName: "setup-account" */ "@/views/SetupAccount.vue"
          ),
        name: "SetupAccount",
        props: true,
      },
      {
        path: "product/:productId",
        component: () =>
          import(
            /* webpackChunkName: "single-product" */ "@/views/SingleProduct.vue"
          ),
        name: "SingleProduct",
      },
    ],
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "page404" */ "@/views/Page404.vue"),
    name: "Page404",
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
