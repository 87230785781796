import api from "../api";

export default {
  namespaced: true,
  state: {
    email: "",
    token: null,
    otherEmails: [],
    lastShippingAddress: null,
    finalShipping: null,
    points: 0,
    isFirstTime: 0,
    hasPassword: false,
    history: [],
    passedChallenge: false,
    confirmationCreds: {},
    isLocked: false,
  },

  getters: {
    getLoadingBackendOrders(state: any) {
      return state.loadingBackendOrders;
    },
    isLoggedIn(state: any) {
      return state.token != null;
    },
    hasPassword(state: any) {
      return state.hasPassword;
    },
    userPoints(state: any) {
      return state.points;
    },
  },

  mutations: {
    SET_LOCKED(state: any, data: any) {
      state.isLocked = data;
    },
    SET_CONFIRMATION_CREDS(state: any, data: any) {
      state.confirmationCreds = data;
    },
    SET_USER_DATA(state: any, data: any) {
      state.email = data.email;
      state.uid = data.uid;
      state.otherEmails = data.other_emails;
      state.lastShippingAddress = data.last_shipping_address;
      state.points = data.points;
    },
    SET_FINAL_SHIPPING(state: any, data: any) {
      state.finalShipping = data;
    },
    SET_HISTORY(state: any, data: any) {
      state.history = data;
    },
    SET_PASSED_CHALLENGE(state: any) {
      state.passedChallenge = true;
    },
    SET_LOGIN_DATA(state: any, data: any) {
      if (data == null) {
        state.token = null;
        state.isFirstTime = null;
        state.hasPassword = null;
      } else {
        if (data.token != null || data.token === null) {
          state.token = data.token;
        }
        if (data.is_first_time != null || data.is_first_time === null) {
          state.isFirstTime = data.is_first_time;
        }
        if (data.has_password != null || data.hasPassword === null) {
          state.hasPassword = data.has_password;
        }
      }
    },
    SET_TOKEN_FOR_RETURN(state: any, data: any) {
      state.token = data;
    },
  },

  actions: {
    UserStats(context: any) {
      return new Promise((resolve, reject) => {
        api
          .user()
          .then((response: any) => {
            context.commit("SET_USER_DATA", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            context.commit("SET_LOGIN_DATA", {});
            reject(e);
          });
      });
    },
    login(context: any, params: any) {
      return new Promise((resolve, reject) => {
        api
          .login(params)
          .then((response: any) => {
            context.commit("SET_LOGIN_DATA", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            context.commit("SET_LOCKED", e.response.data.isLocked);
            reject(e);
          });
      });
    },
    confirmAuthCreds(context: any, data: any) {
      return new Promise((resolve, reject) => {
        api
          .confirmAuthCreds(data.step, data.fields)
          .then((response) => {
            context.commit("SET_CONFIRMATION_CREDS", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            context.commit("SET_LOCKED", e.response.data.isLocked);
            reject(e);
          });
      });
    },
    lockUserForDuration(context: any, data: any) {
      return api.lockUserForDuration(data.email, data.step);
    },
  },
};
