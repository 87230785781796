<script>
import Nav from "@/components/Nav.vue";
import api from "@/api";

export default {
  name: "Main",
  components: {
    navi: Nav
  },
  methods: {
    login() {
      localStorage.removeItem("next");
      this.$router.push({ name: "Login" });
    },
    logout() {
      this.$store.commit("SET_LOGIN_DATA", null);
      this.$store.commit("SET_USER_DATA", {});
      localStorage.removeItem("auth");
      window.location.reload();
    },
    refreshToken() {
      api.refreshToken();
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.Auth.token;
    },
    points() {
      return this.$store.state.Auth.points;
    }
  },
  created() {
    console.log("App created.");
    const token = localStorage.getItem("auth");
    setInterval(this.refreshToken, 10 * 60 * 1000);
    if (token) {
      this.$store.commit("Auth/SET_LOGIN_DATA", {
        token: token
      });
    }

    if (token) {
      this.$store.dispatch("Cart/setCart");
    }
  }
};
</script>
<template>
  <div class="site-body">
    <navi />
    <router-view />
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Rubik+Mono+One&family=Rubik:ital,wght@0,400;0,500;0,700;0,900;1,400&family=Source+Sans+Pro:wght@200;300;400;600;700&family=Yanone+Kaffeesatz:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Coda+Caption:wght@800&family=Modak&family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap");
:root {
  --baloo: "Baloo Tammudu", sans-serif;
  --open-sans: "Open Sans", sans-serif;
  --source-sans: "Source Sans Pro", sans-serif;
  --rubik: "Rubik", sans-serif;
  --montserrat: "Monserrat", sans-serif;
  --marker: "Permanent Marker", sans-serif;
  --raleway: "Raleway", sans-serif;
  --kalam: "Kalam", sans-serif;
  --quicksand: "Quicksand", sans-serif;
  --color-primary-dark: #031927;
  --color-light-grey: #f5f6fa;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-spring-green: rgba(49, 233, 129);
  --color-deep-yellow: rgb(250, 149, 0);
  --color-deep-green: #00241b;
  --color-lapis-blue: #145c9e;
  --color-cool-blue: #108ee9;
  --color-solid-blue: #155592;
  --color-white: #fcfcfc;
  --color-lapis-blue-hover: rgba(20, 92, 158, 0.3);
  --color-purple: #321637;
  --color-teal: #12b886;
  --color-lighter-teal: #e7f5ff;
  --red: #e1332d;
  --kupiku-red: #e73b45;
  --color-purple-transparent: rgb(218, 68, 83, 0.2);
  --color-purple-transparent-2: rgb(218, 68, 83, 0.98);
  --bright-green: #11e95b;
  --rich-black: #020202;
  --naples-yellow: #ffe66d;
  --mustard: rgba(255, 212, 71);
  --yellow-2: rgba(242, 221, 110);
  --blue: #275efe;
  --bluetiful: rgba(59, 96, 228);
  --gunmetal: #292f36;
  --material-shadow-1: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  --material-shadow-2: 0 2px 1px rgba(0, 0, 0, 0.1),
    0 1px 12px rgba(0, 0, 0, 0.1);
  --material-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  --material-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  --material-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2px 6px rgba(0, 0, 0, 0.3);
  --shadow-light-blue: 0 4px 8px rgba(61, 90, 241, 0.4);
  --small-shadow-dark: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.06);
  --small-shadow-light: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.06);
  --small-shadow-light-darker: 0 0.2rem 1.9rem rgba(0, 0, 0, 0.08);
  --line: 0.8px solid var(--color-grey-light-4);
}
@import "@/assets/styles/_components.scss";
/*
MEDIA QUERY HUB: MOHOL


$breakpoint var
-sm-phone = small phone viewport =
-phone = phone viewport = <=600px
 nb-tab-p = tablet portrait viewport = <=900px
-tab-l tablet landscape viewport = <=1200px
-bg-dg = big descktop viewport = <=1800px
*/
@mixin respond($breakpoint) {
  @if ($breakpoint == phone) {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if ($breakpoint == tab_p) {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if ($breakpoint == tab_l) {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if ($breakpoint == bg_dt) {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

// Animations
@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.89);
  }
  to {
    transform: scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  width: 100%;
  font-family: "Hind Madurai", sans-serif;
  // padding-top: 5.5rem;
}
html {
  // width: 100%;
  // height: 100%;
  box-sizing: border-box;
  font-size: 62.5%; //1rem = 10px yaaaaay! better for uniformity stuff 10px/16px = 62.5

  @include respond(tab_l) {
    font-size: 56.25%; //1rem = 9px
  }
  @include respond(tab_p) {
    font-size: 52.5%; //1rem = 8px
  }
  @include respond(bg_dt) {
    font-size: 75%; //1 rem = 12px
  }
}
.site-body {
  position: relative;
  min-width: 100%;

  @include respond(tab_l) {
  }
  @include respond(tab_p) {
    // min-width: 100%;
  }
  @include respond(bg_dt) {
    //1 rem = 12px
  }
}
.list-item-end {
  &--login {
    color: #2f3545;
  }
  .points-btn {
    &:hover {
      color: var(--color-white);
    }
  }
}
.tab-l {
  @include respond(phone) {
    display: none;
  }
}
.mobi {
  // display: none;
  @include respond(tab_l) {
    display: none;
  }

  @include respond(tab_p) {
    display: none;
  }
  @include respond(bg-dt) {
  }
  @include respond(phone) {
    display: inline-block;
  }
}
.drawer-icon {
  color: var(--color-primary-dark);
  font-size: 1.7rem;
}
</style>
