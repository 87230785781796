import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Auth from "./auth";
import Product from "./products";
import Cart from "./cart";
import Nav from "./nav";

export default new Vuex.Store({
  modules: {
    Auth,
    Product,
    Cart,
    Nav
  }
});
