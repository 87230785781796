import api from "../api";

export default {
  namespaced: true,
  state: {
    products: [],
    totalRegular: null,
    featuredProducts: [],
  },
  getters: {
    getProduct(state: any, id: number) {
      const filtered = state.products.filter((elem: any) => {
        if (elem.id == id) {
          return true;
        }
        return false;
      });
      if (filtered.length) {
        return filtered[0];
      }
      return null;
    },
    allProducts: (state: any) => {
      return state.products;
    },
    productsCount: (state: any) => {
      return state.totalRegular;
    },
  },
  mutations: {
    SET_PRODUCTS(state: any, val: any) {
      state.products = val.products;
      state.totalRegular = val.total_number;
    },
    SET_FEATURED_PRODUCTS(state: any, val: any) {
      state.featuredProducts = val;
    },
  },
  actions: {
    loadProducts(
      context: any,
      pagination: {
        page: 8;
        itemsPerPage: 1;
      }
    ) {
      return new Promise((resolve, reject) => {
        const page = pagination.page;
        const pageSize = pagination.itemsPerPage;
        const includeInactive = null;
        api
          .getProducts(includeInactive, page, pageSize)
          .then((data: any) => {
            context.commit("SET_PRODUCTS", data.data);
            resolve(data);
          })
          .catch((e: any) => {
            console.error(e);
            reject(e);
          });
      });
      // });
    },
    loadFeatured(context: any) {
      api.getFeaturedProducts().then((data: any) => {
        context.commit("SET_FEATURED_PRODUCTS", data.data.products);
      });
    },
  },
};
