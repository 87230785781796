import api from "../api";

export default {
  namespaced: true,
  state: {
    cart: []
  },
  getters: {
    cartCount(state: any) {
      return state.cart.length;
    },
    cartTotalPoints(state: any) {
      let total = 0;
      state.cart.forEach((element: any) => {
        total += element.product.points * element.quantity;
      });
      return total;
    }
  },
  mutations: {
    ADD_ITEM: (state: any, data: any) => {
      const itemInCart = state.cart.find((item: any) => {
        return item.product.id === data.product.id;
      });
      if (itemInCart) {
        itemInCart.quantity += data.quantity;
        return;
      }
      state.cart.push(data);
    },

    SET_CART: (state: any, data: any) => {
      state.cart = data.cart;
    },

    REMOVE_ITEM_FROM_CART: (state: any, product: any) => {
      state.cart = state.cart.filter((item: any) => {
        return item.product.id !== product;
      });
    },

    CLEAR_CART: (state: any) => {
      state.cart.splice(0);
    },

    SAVE_CART: (state: any, items: any[]) => {
      for (const itemIndex in items) {
        const item = items[itemIndex];
        state.cart.push({
          product: item.product,
          quantity: item.quantity
        });
      }
    }
  },
  actions: {
    addToCart(context: any, cartData: any) {
      return new Promise((resolve, reject) => {
        api
          .saveCart({
            product: cartData.product.id,
            quantity: cartData.quantity
          })
          .then(() => {
            context.commit("ADD_ITEM", cartData);
            resolve();
          })
          .catch(e => {
            console.error("Failed");
            reject(e);
          });
      });
    },
    remove(context: any, product: any) {
      context.commit("REMOVE_ITEM_FROM_CART", product);
      api.deleteItemCart(product);
    },
    deleteCart(context: any) {
      api.deleteCart().then(() => {
        context.commit("CLEAR_CART");
      });
    },
    setCart(context: any) {
      return new Promise((resolve, reject) => {
        api
          .getCart()
          .then((response: any) => {
            context.commit("SET_CART", response.data);
            resolve();
          })
          .catch(e => {
            console.error(e);
            reject(e);
          });
      });
    }
  }
};
