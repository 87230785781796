export default {
  namespaced: true,
  state: {
    page: null,
    activeLink: null
  },
  getters: {
    getPage(state: any) {
      return state.page;
    },
    getActive(state: any) {
      return state.activeLink;
    }
  },
  mutations: {
    SET_PAGE(state: any, param: string) {
      state.page = param;
    },
    SET_ACTIVE(state: any, val: number) {
      state.activeLink = val;
    }
  },
  actions: {}
};
